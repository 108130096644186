import { FunctionComponent } from 'react'
import { ReactI18NextChild } from 'react-i18next'
import styled from 'styled-components'

import { CenteredLayout } from '../../../../../components/CenteredLayout/CenteredLayout.component'
import Heading from '../../../../../components/Heading'
import { ColorEnum, Spacing } from '../../../../../shared/enums'
import { WeakIdentificationStepper } from '../WeakIdentificationStepper/WeakIdentificationStepper.component'

const StyledHeader = styled.div`
  background: ${ColorEnum.bfLightGray};
  text-align: center;
  padding-top: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
`

type Props = {
  children: ReactI18NextChild
  title: string
  currentStep: number
  numberOfSteps: number
  isTestMode: boolean
}

const WeakIdentificationLayoutWrapper: FunctionComponent<Props> = (props: Props) => {
  const { children, title, currentStep, numberOfSteps, isTestMode } = props

  return (
    <>
      {title && title.length > 0 ? (
        <StyledHeader>
          <Heading level={'h5'}>
            {title} {isTestMode ? '(TEST MODE)' : ''}
          </Heading>
        </StyledHeader>
      ) : null}
      <WeakIdentificationStepper currentStep={currentStep} numberOfSteps={numberOfSteps} />
      <CenteredLayout>{children}</CenteredLayout>
    </>
  )
}

export { WeakIdentificationLayoutWrapper }
