import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

// TO-DO remove this when we go to live
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import {
  WeakIdentificationRequestKeys,
  handleWeakIdentificationAuthorizationLink,
} from '../../WeakIdentification.requests'
import {
  FormInputElementDefaultProps,
  SessionStorageKeys,
  WeakIdentificationModes,
  searchQueryParameters,
} from '../../WeakIdentification.schema'
import { setWeakIdentificationRedirectUrl } from '../../WeakIdentification.utils'

/**
 * WeakIdentification e-mail step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const EmailForm: FunctionComponent<FormInputElementDefaultProps> = (
  props: FormInputElementDefaultProps
) => {
  const { testMode, dispatch } = props
  // We need to use useTranslation hook for this occasion
  const { i18n } = useTranslation()
  // We set redirect url to the session storage to be able to redirect user back to the correct page
  const searchParams = new URLSearchParams(window.location.search)
  // This searchQuery parameter is assigned on the parent component. It's also validated everytime searchQuery changes
  // and if it is missing we redirect user to the first step.
  const mode = searchParams.get(searchQueryParameters.mode) as WeakIdentificationModes
  // Reference for iframe to make it work more dynamically
  const iframeReference = useRef<HTMLIFrameElement>(null)

  // TO-DO remove these when we go to the production
  const testEmailRef = useRef<HTMLInputElement>(null)
  const navigation = useNavigate()

  const authorizationRequest = useQuery(
    [WeakIdentificationRequestKeys.authenticationStep],
    () => handleWeakIdentificationAuthorizationLink(mode),
    { retry: false }
  )

  // UseEffect in here is used to update the iframe src attribute with the authorizationRequest data.
  // We hook it to use isLoading due we don't want this to be rendered multiple times even if the state
  // changes.
  useEffect(() => {
    dispatch(setSpinner({ visible: authorizationRequest.isLoading }))

    if (authorizationRequest.isLoading) {
      return
    }

    // We set the iframes src attribute to the authorizationRequest data and add the locale to the query
    if (authorizationRequest.data && iframeReference.current) {
      const src = `${authorizationRequest.data}?locale=${i18n.language}`
      iframeReference.current.src = src
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationRequest.isLoading, i18n.language, authorizationRequest.data])

  // We set the redirect url to the session storage to be able to redirect user back to the correct page
  useEffect(() => {
    setWeakIdentificationRedirectUrl(mode)
  }, [mode])

  // TO-DO remove this when we go to live
  const handleTest = () => {
    if (!testMode) {
      return console.warn('Test mode is not enabled')
    }

    if (!testEmailRef.current) {
      return console.warn('Test e-mail input not found')
    }

    // Now we inject e-mail to the session storage to be able to use it in the next steps
    sessionStorage.setItem(SessionStorageKeys.testEmail, testEmailRef.current.value)
    // We navigate user to the next step
    console.log('Proceeding to the next step')
    navigation(
      `/register/weak-identification?view=miscInformation&mode=${WeakIdentificationModes.test}`
    )
  }

  // This shouln't happen ever due to the validation on the parent component but if it manages to get here
  // we're gonna return null and not render anything. This might cause some problems if this path is used
  // as a IFrame or a standalone component but in this case it's not. But if this causes the problems
  // just refactor properties to have this property injected from the parent component.
  if (!mode) {
    return null
  }

  // Add loading indicator in here
  if (authorizationRequest.isLoading) {
    return null
  }

  if (testMode) {
    return (
      <div>
        <h3>
          Test mode is enabled. Write your e-mail and click the button to proceed to the next step.
        </h3>
        <input
          style={{
            display: 'block',
            border: '1px solid black',
            width: '100%',
            marginTop: '1rem',
            marginBottom: '1rem',
            boxSizing: 'border-box',
            padding: '0.5rem',
          }}
          ref={testEmailRef}
          type={'email'}
          placeholder="Your e-mail address for the test"
        />
        <RoundedButton variant={RoundedButtonVariants.primary} onClick={() => handleTest()}>
          Proceed
        </RoundedButton>
      </div>
    )
  }

  return (
    <iframe
      sandbox={
        'allow-forms allow-same-origin allow-top-navigation allow-scripts allow-popups allow-popups-to-escape-sandbox'
      }
      referrerPolicy="origin"
      width={'100%'}
      name={'Weak authentication email form'}
      ref={iframeReference}
      style={{ overflow: 'hidden', border: 'none', minHeight: 900 }}
    />
  )
}

export { EmailForm }
