import { handleAPIGet, handleAPIPost } from '../../../utils/network-utils'
import {
  WeakIdentificationJoinOrganizationStepType,
  WeakIdentificationMiscInformationRequestResponseType,
  WeakIdentificationModes,
  WeakIdentificationPrivilegeCheckResponseType,
  WeakIdentificationValidationMiscInformationStepType,
} from './WeakIdentification.schema'

/**
 * Store query- and mutationkeys in the same place as requests
 */
export enum WeakIdentificationRequestKeys {
  authenticationStep = 'WEAK_IDENTICATION_AUTHENTICATION_STEP',
  emailStep = 'WEAK_IDENTICATION_EMAIL_STEP',
  authenticationCode = 'WEAK_IDENTICATION_AUTHENTICATION_CODE',
  miscInformation = 'WEAK_IDENTICATION_MISC_INFORMATION_STEP',
  checkOrganization = 'WEAK_IDENTICATION_CHECK_ORGANIZATION',
  joinToOrganization = 'WEAK_IDENTICATION_JOIN_TO_ORGANIZATION',
  preSession = 'WEAK_IDENTICATION_PRE_SESSION',
}

// #region starts for the requests

/**
 * Fetches the authorization link for the weak identication
 * @param type
 * @returns {Promise<string>}
 */
export const handleWeakIdentificationAuthorizationLink = async (
  type: WeakIdentificationModes
): Promise<string> => {
  return await handleAPIGet<string>(`/weak-identification?type=${type}`)
}

/**
 * Checks if the pre-session exists
 */
export const handleWeakIdentificationPreSession = async (): Promise<string> => {
  return await handleAPIGet<string>('/weak-identification/pre-session')
}

/**
 * Saves misc information about the user
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationMiscInformation = async (
  body: WeakIdentificationValidationMiscInformationStepType
): Promise<WeakIdentificationMiscInformationRequestResponseType> => {
  return await handleAPIPost<
    WeakIdentificationMiscInformationRequestResponseType,
    WeakIdentificationValidationMiscInformationStepType
  >('/weak-identification/misc-information', body)
}

/**
 * Checks if organizations business id is valid
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationCheckOrganization = async (
  body: WeakIdentificationJoinOrganizationStepType
): Promise<WeakIdentificationPrivilegeCheckResponseType> => {
  return await handleAPIPost<
    WeakIdentificationPrivilegeCheckResponseType,
    WeakIdentificationJoinOrganizationStepType
  >('/weak-identification/check-organization', body)
}

/**
 * Joins to an organization
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationJoinOrganization = async (
  body: WeakIdentificationJoinOrganizationStepType
): Promise<string> => {
  return await handleAPIPost<string, WeakIdentificationJoinOrganizationStepType>(
    '/weak-identification/join-to-organization',
    body
  )
}
// #endregion for the requests
