import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'

interface Props extends React.HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode[]
}

const StyledFormContainer = styled.form`
  & > label {
    text-transform: uppercase;
  }
  & > input {
    border: 1px solid ${ColorEnum.bfBlue};
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: ${ColorEnum.white};
    text-transform: none;
    margin-bottom: 0.5rem;
  }
  & > .checkbox-row {
    display: flex;
  }
  & > .checkbox-row > input {
    border: 1px solid ${ColorEnum.bfBlue};
    border-radius: 3px;
    min-width: 1.625rem;
    min-height: 1.625rem;
    background-color: ${ColorEnum.bfLightGray};
    all: revert !important;
  }
  & > .checkbox-row > label {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    color: #002ea2;
  }
  .error-message {
    color: ${ColorEnum.bfRed};
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const ReactFormContainer: FunctionComponent<Props> = (props: Props) => {
  const { children, ...rest } = props

  return (
    <StyledFormContainer className="bfsso-form" {...rest}>
      {children}
    </StyledFormContainer>
  )
}

export default ReactFormContainer
