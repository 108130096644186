import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Heading from '../../../../../components/Heading'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import { Spacing } from '../../../../../shared/enums'
import { FormInputElementDefaultProps } from '../../WeakIdentification.schema'
import { getWeakIdentificationTestEmail } from '../../WeakIdentification.utils'

const StyledSuccessSimpleWrapper = styled.div`
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
`

/**
 * This view just shows a success message and a button to navigate to the next path
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const SuccessSimple: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { t } = props
  const navigate = useNavigate()
  const email = getWeakIdentificationTestEmail()
  console.log(email, 'email')

  return (
    <StyledSuccessSimpleWrapper>
      <Heading level={'h4'} color={'bf-blue'}>
        {t('weak-identification.success-title')}
      </Heading>
      <Spacer size={'large'} />
      <Paragraph>{t('weak-identification.success-description', { email })}</Paragraph>
      <Spacer size={'large'} />
      <RoundedButton
        onClick={() => navigate('/')}
        variant={RoundedButtonVariants.primary}
        type={'button'}
      >
        {t('weak-identification.success-submit')}
      </RoundedButton>
    </StyledSuccessSimpleWrapper>
  )
}

export { SuccessSimple }
