import { useQuery } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  WeakIdentificationRequestKeys,
  handleWeakIdentificationPreSession,
} from './WeakIdentification.requests'
import {
  FormConfiguration,
  FormInput,
  WeakIdentificationModes,
  WeakIdentificationSearchQueryType,
  searchQueryParameters,
} from './WeakIdentification.schema'
import { EmailForm } from './components/forms/EmailForm.component'
import { JoinToOrganizationForm } from './components/forms/JoinToOrganizationForm.component'
import { MiscInformationForm } from './components/forms/MiscInformationForm.component'
import { SuccessSimple } from './components/forms/SuccessSimple.component'
import { WeakIdentificationLayoutWrapper } from './components/layout/WeakIdentificationLayoutWrapper.component'
import { useCheckWeakIdentificationRouteValidity } from './hooks/WeakIdentification.hooks'

/**
 * ViewIndexes for the stepper
 */
enum ViewIndexes {
  email = 1,
  miscInformation = 2,
  joinToOrganization = 3,
  success = 4,
}

/**
 * Configuration for form inputs to be rendered
 */
const WeakIdentificationSubRoutes: FormConfiguration = {
  [FormInput.email]: {
    element: EmailForm,
    indexNumber: ViewIndexes.email,
    translationKey: '',
  },
  [FormInput.miscInformation]: {
    element: MiscInformationForm,
    indexNumber: ViewIndexes.miscInformation,
    translationKey: 'weak-identification.misc-information-view-title',
  },
  [FormInput.joinToOrganization]: {
    element: JoinToOrganizationForm,
    indexNumber: ViewIndexes.joinToOrganization,
    translationKey: 'weak-identification.join-to-organization-view-title',
  },
  [FormInput.success]: {
    element: SuccessSimple,
    indexNumber: ViewIndexes.success,
    translationKey: '',
  },
}

const FIVE_SECONDS_IN_MS = 5000

/**
 * Basic wrapper for WeakIdentification workflow
 * @returns {FunctionComponent}
 */
const WeakIdentification: FunctionComponent = () => {
  const navigation = useNavigate()
  // We call this only once to prevent a duplication of code and pass this as a prop to children
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchParamsValidation = useCheckWeakIdentificationRouteValidity()
  const currentView = searchParamsValidation.searchParams.get(
    searchQueryParameters.view
  ) as FormInput

  const preSession = useQuery(
    [WeakIdentificationRequestKeys.preSession],
    handleWeakIdentificationPreSession,
    {
      refetchInterval: FIVE_SECONDS_IN_MS,
    }
  )

  console.log(preSession, 'preSession')

  // TO-DO remove this when we go to live
  const isTestMode = useMemo(() => {
    return (
      searchParamsValidation.searchParams.get(searchQueryParameters.mode) ===
      WeakIdentificationModes.test
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new URLSearchParams().get(searchQueryParameters.mode)])

  /**
   * Gets the search parameters from the URL
   * @returns {Object} search parameters
   */
  const getSearchParameters = () => {
    const searchParameters = new URLSearchParams(window.location.search)
    const view = searchParameters.get(searchQueryParameters.view) as FormInput
    const mode = searchParameters.get(searchQueryParameters.mode) as WeakIdentificationModes

    return {
      view,
      mode,
    } as WeakIdentificationSearchQueryType
  }

  /**
   * Handles the changes of search parameters adding the new value to the search query
   * @param key
   * @param value
   */
  const setSearchParameter = (
    key: searchQueryParameters,
    value: FormInput | WeakIdentificationModes
  ) => {
    const searchParameters = getSearchParameters()

    if (key === searchQueryParameters.view) {
      searchParameters.view = value as FormInput
    }

    if (key === searchQueryParameters.mode) {
      searchParameters.mode = value as WeakIdentificationModes
    }

    navigation({
      pathname: '/register/weak-identification',
      search: `?view=${searchParameters.view}&mode=${searchParameters.mode}`,
    })
  }

  /**
   * Basic helper function that handles router navigation of the view
   * @param key
   */
  const setView = (key: FormInput) => {
    setSearchParameter(searchQueryParameters.view, key)
  }

  /**
   * Basic helper function that handles router navigation of the mode
   * @param key
   */
  const setMode = (key: WeakIdentificationModes) => {
    setSearchParameter(searchQueryParameters.mode, key)
  }

  /**
   * When searchParams change we want to validate if the route is valid.
   * If not we navigate to the root.
   */
  useEffect(() => {
    const isValid = searchParamsValidation.validate()

    if (!isValid) {
      navigation({
        pathname: '/',
      })
    }
  }, [searchParamsValidation.searchParams, searchParamsValidation, navigation])

  if (!currentView || !WeakIdentificationSubRoutes[currentView]) {
    return <div />
  }

  /**
   * This function handles form onSuccesses. Basically we check the next key of
   * on configuration object and push router to the next possible place.
   * @param currentFormInput
   * @returns {void}
   */
  const handleOnSuccess = (currentFormInput: FormInput) => {
    const currentWeakIdentificationSubRouteKeys: FormInput[] = Object.keys(
      WeakIdentificationSubRoutes
    ) as FormInput[]

    if (!currentWeakIdentificationSubRouteKeys.includes(currentFormInput)) {
      // This happens when currentFormInput doesn't exist. We stop the function call in there
      // if this would ever happen. Due we have an validation for searchParameters we don't
      // have this issue like ever but sometimes things fails so it's important to have this kind
      // of checks.
      return
    }

    const currentKeyIndex = currentWeakIdentificationSubRouteKeys.indexOf(currentFormInput)

    // If currentKeyIndex doesn't exist the value is -1
    if (currentKeyIndex < 0) {
      // This shouldn't happen neither but to be sure lets add this statement here also
      return
    }

    // This is quite ugly way to handle this. Due currentKeyIndex is an constant we can't use currentKeyIndex++ kind
    // of approach without mutating also the original value.
    const nextKeyIndex = currentKeyIndex + 1
    // We check if the nextKey exists
    const nextKey = currentWeakIdentificationSubRouteKeys[nextKeyIndex]

    if (!nextKey) {
      // this is the last step aka "Success!"
      return
    }

    // Change searchqueries "view" parameter to step on the next step
    return setView(nextKey)
  }

  // Shorthand to find a current routes location
  const component = WeakIdentificationSubRoutes[currentView]

  return (
    <WeakIdentificationLayoutWrapper
      //TO-DO remove when we go live
      isTestMode={isTestMode}
      title={t(component.translationKey)}
      numberOfSteps={Object.keys(WeakIdentificationSubRoutes).length}
      currentStep={component.indexNumber}
    >
      <component.element
        t={t}
        dispatch={dispatch}
        testMode={isTestMode}
        onSuccess={(currentFormKey: FormInput) => handleOnSuccess(currentFormKey)}
        onModeChange={(mode: WeakIdentificationModes) => setMode(mode)}
      />
    </WeakIdentificationLayoutWrapper>
  )
}

export default WeakIdentification
