import { z } from 'zod'

export enum ACRValues {
  Tupas = 'tupas',
  Cidlog = 'cidlog',
  ESuomi = 'esuomi',
  EmailConfirmation = 'emailconfirmation',
}

export const ACRValueValidation = z.nativeEnum(ACRValues)
