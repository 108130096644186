import { SessionStorageKeys, WeakIdentificationModes } from './WeakIdentification.schema'

/**
 * Sets the redirect url for the weak identication.
 * @param mode
 */
export function setWeakIdentificationRedirectUrl(mode: WeakIdentificationModes) {
  const redirectUrl = `/weak-identification?view=miscInformation&mode=${mode}`
  sessionStorage.setItem(SessionStorageKeys.redirectUrl, redirectUrl)
}

/**
 * Sets e-mail for the weak identication.
 * @param email
 */
export function setWeakIdentificationEmail(email: string) {
  sessionStorage.setItem(SessionStorageKeys.email, email)
}

/**
 * Gets the redirect url for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationRedirectUrl(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.redirectUrl)
}

/**
 * Gets the e-mail for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationEmail(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.email)
}

/**
 * Sets the test bf-id for the weak identication.
 * @param bfId
 */
export function setWeakIdentificationBfId(bfId: string) {
  sessionStorage.setItem(SessionStorageKeys.testBfId, bfId)
}

/**
 * Gets the test bf-id for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationBfId(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.testBfId)
}

/**
 * Gets the test e-mail for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationTestEmail(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.testEmail)
}
