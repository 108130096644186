/**
 * All the validations that are correspondent for WeakIdentification workflow are
 * stored in here. Here we also export typings by using zod infer to be used on the
 * frontend side. In the future it would be a good idea to use monorepo to store these
 * kind of validations to be used everywhere in this application.
 */
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { TFunction } from 'i18next'
import { FunctionComponent } from 'react'
import { z } from 'zod'

import { isValidName, validateFinnishBusinessID } from '../../../utils/validation-utils'

// #region Enums
export enum SessionStorageKeys {
  email = 'bf_session_email',
  redirectUrl = 'bf_session_redirect_url',
  testEmail = 'bf_session_test_email',
  testBfId = 'bf_session_test_bfid',
}

export enum searchQueryParameters {
  view = 'view',
  mode = 'mode',
}

export enum WeakIdentificationModes {
  registered = 'registered',
  unRegistered = 'unregistered',
  test = 'test',
}

export enum FormInput {
  email = 'email',
  miscInformation = 'miscInformation',
  joinToOrganization = 'joinToOrganization',
  success = 'success',
}
// #endregion

//#region Zod validations
// This validation is for searchQuery of weakIdentification wrapper
export const WeakIdentificationSearchQuery = z.object({
  view: z.nativeEnum(FormInput),
  mode: z.nativeEnum(WeakIdentificationModes),
})

// Weak e-mail validation. Basically just checks if the e-mail includes @ and .
export const WeakIdentificationValidationEmailStep = z.object({
  email: z.string().email(),
  mode: z.nativeEnum(WeakIdentificationModes),
})

export const WeakIdentificationValidationVerificationCodeStep = z.object({
  verificationCode: z.string(),
})

export const WeakIdentificationValidationMiscInformationStep = z.object({
  firstName: z
    .string()
    .min(2)
    .max(128)
    .refine(name => {
      return isValidName(name)
    }),
  lastName: z
    .string()
    .min(2)
    .refine(name => {
      return isValidName(name)
    }),
  // Checkboxes
  newsLetters: z.boolean().optional(),
  invitations: z.boolean().optional(),
  questionaries: z.boolean().optional(),
  marketOpportunities: z.boolean().optional(),
  marketApproval: z.boolean().refine(booleanValue => {
    return booleanValue
  }),
  marketDisapproval: z.boolean().optional(),
  email: z.string().optional(),
})

export const WeakIdentificationValidationJoinToOrganizationStep = z.object({
  businessId: z.string().refine(businessId => {
    return validateFinnishBusinessID(businessId)
  }),
  bfId: z.string().optional(),
  email: z.string().optional(),
})

export const WeakIdentificationMiscInformationRequestResponse = z.object({
  bfId: z.string(),
  crmId: z.string(),
  email: z.string(),
})

export const PrivilegeCheckResponse = z.object({
  isInCrm: z.boolean(),
  validEmailDomain: z.boolean(),
  hasErrors: z.boolean(),
  role: z.enum(['L1', 'L2']),
  trustedDomains: z.array(z.string()),
  usersDomain: z.string().nullable(),
})

// #endregion Zod validations

// #region Misc types

// Form configuration works like we have an query parameter called "view" which has a value of formInput.
// By using FormInput we're gonna render a FormInputs element
export type FormInputElementDefaultProps = {
  onSuccess: (currentFormInputKey: FormInput) => void
  t: TFunction
  onModeChange?: (mode: WeakIdentificationModes) => void
  testMode?: boolean
  dispatch: Dispatch<AnyAction>
}

export type FormInputConfiguration = {
  element: FunctionComponent<FormInputElementDefaultProps>
  indexNumber: number
  translationKey: string
}

export type FormConfiguration = Record<FormInput, FormInputConfiguration>

// #endregion

// #region for Exporting types
export type WeakIdentificationSearchQueryType = z.infer<typeof WeakIdentificationSearchQuery>
export type WeakIdentificationEmailStepType = z.infer<typeof WeakIdentificationValidationEmailStep>
export type WeakIdentificationValidationVerificationCodeStepType = z.infer<
  typeof WeakIdentificationValidationVerificationCodeStep
>
export type WeakIdentificationValidationMiscInformationStepType = z.infer<
  typeof WeakIdentificationValidationMiscInformationStep
>
export type WeakIdentificationJoinOrganizationStepType = z.infer<
  typeof WeakIdentificationValidationJoinToOrganizationStep
>
export type WeakIdentificationMiscInformationRequestResponseType = z.infer<
  typeof WeakIdentificationMiscInformationRequestResponse
>
export type WeakIdentificationPrivilegeCheckResponseType = z.infer<typeof PrivilegeCheckResponse>
// #endregion
